.socialImageLink {
    display: block;
    margin: 30px auto 0 auto;
    width: 32px;
    height: 32px;
}

.socialImage {
    height: 100%;
}

.address {
    margin-top: 10px;
    font-size: 80%;
    text-align: center;
    margin-bottom: 30px;
}
