.header {
    font-family: 'Adler', serif;
    color: #1A1919;
    background-color: white;
    padding-left: 6px;
    padding-right: 6px;
    font-weight: bold;
    margin: 4px 0;
}

.text {
    line-height: 1.125;
    vertical-align: bottom;
}
