.link {
    display: block;
    width: 250px;
    margin: 25px auto;
}

.image {
    width: 250px;
    height: 74px;
    display: block;
}
