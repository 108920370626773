/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
    font-family: 'Adler';
    src: url('./Adler.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Bold Condensed';
    src: url('./MYRIADPRO-BOLDCOND.OTF') format('opentype');
}
