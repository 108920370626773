.suggesties {
    font-family: 'Adler', serif;
    color: white;
    text-align: center;
    font-size: 120%;
    margin: 20px 0;
    padding: 5px 0;

    background: linear-gradient(to right, white 33%, rgba(0,0,0,0) 0%) top/6px 1px repeat-x, linear-gradient(to right, white 33%, rgba(0,0,0,0) 0%) bottom/6px 1px repeat-x;
}

.suggesties span {
    line-height: 1.125;
    vertical-align: bottom;
}
