.item {
    font-family: 'Adler', serif;
    letter-spacing: -1px;
    display: flex;
    align-items: baseline;
    color: white;
    font-size: 15px;
    margin: 1px 0;
}

.content {

}

.title {
    margin-right: 6px;
}

.description {
    font-size: 70%;
}

.filler {
    flex-grow: 1;
}

.price {
    align-self: flex-end;
    margin-left: 10px;
}
