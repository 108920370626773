.wrapper {
    margin-top: 30px;
}

.logoRow {
    height: 230px;
}

.logo {
    display: block;
    height: 100%;
    margin: 0 auto;
}

.name {
    margin-top: 10px;
    font-size: 20px;
    text-align: center;
}
